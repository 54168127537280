let wasm;

const heap = new Array(128).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) { return heap[idx]; }

let heap_next = heap.length;

function dropObject(idx) {
    if (idx < 132) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

const cachedTextDecoder = new TextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachedUint8Memory0 = null;

function getUint8Memory0() {
    if (cachedUint8Memory0 === null || cachedUint8Memory0.byteLength === 0) {
        cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

let WASM_VECTOR_LEN = 0;

function passArray8ToWasm0(arg, malloc) {
    const ptr = malloc(arg.length * 1);
    getUint8Memory0().set(arg, ptr / 1);
    WASM_VECTOR_LEN = arg.length;
    return ptr;
}

let cachedInt32Memory0 = null;

function getInt32Memory0() {
    if (cachedInt32Memory0 === null || cachedInt32Memory0.byteLength === 0) {
        cachedInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachedInt32Memory0;
}

function getArrayU8FromWasm0(ptr, len) {
    return getUint8Memory0().subarray(ptr / 1, ptr / 1 + len);
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        wasm.__wbindgen_exn_store(addHeapObject(e));
    }
}
/**
*/
export class Chip8Classic {

    static __wrap(ptr) {
        const obj = Object.create(Chip8Classic.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_chip8classic_free(ptr);
    }
    /**
    */
    constructor() {
        const ret = wasm.chip8classic_new();
        return Chip8Classic.__wrap(ret);
    }
    /**
    * @returns {Registers}
    */
    registers_ws() {
        const ret = wasm.chip8classic_registers_ws(this.ptr);
        return Registers.__wrap(ret);
    }
    /**
    * @param {Uint8Array} rom
    */
    load_rom_ws(rom) {
        const ptr0 = passArray8ToWasm0(rom, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.chip8classic_load_rom_ws(this.ptr, ptr0, len0);
    }
    /**
    */
    reset_ws() {
        wasm.chip8classic_reset_ws(this.ptr);
    }
    /**
    */
    reset_hard_ws() {
        wasm.chip8classic_reset_hard_ws(this.ptr);
    }
    /**
    */
    pause_ws() {
        wasm.chip8classic_pause_ws(this.ptr);
    }
    /**
    * @returns {boolean}
    */
    paused_ws() {
        const ret = wasm.chip8classic_paused_ws(this.ptr);
        return ret !== 0;
    }
    /**
    * @returns {boolean}
    */
    beep_ws() {
        const ret = wasm.chip8classic_beep_ws(this.ptr);
        return ret !== 0;
    }
    /**
    * @returns {Uint8Array}
    */
    vram_ws() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.chip8classic_vram_ws(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v0 = getArrayU8FromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 1);
            return v0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    */
    clock_ws() {
        wasm.chip8classic_clock_ws(this.ptr);
    }
    /**
    */
    clock_dt_ws() {
        wasm.chip8classic_clock_dt_ws(this.ptr);
    }
    /**
    */
    clock_st_ws() {
        wasm.chip8classic_clock_st_ws(this.ptr);
    }
    /**
    * @param {number} key
    */
    key_press_ws(key) {
        wasm.chip8classic_key_press_ws(this.ptr, key);
    }
    /**
    * @param {number} key
    */
    key_lift_ws(key) {
        wasm.chip8classic_key_lift_ws(this.ptr, key);
    }
    /**
    */
    vblank_ws() {
        wasm.chip8classic_pause_ws(this.ptr);
    }
}
/**
*/
export class Chip8Neo {

    static __wrap(ptr) {
        const obj = Object.create(Chip8Neo.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_chip8neo_free(ptr);
    }
    /**
    */
    constructor() {
        const ret = wasm.chip8neo_new();
        return Chip8Neo.__wrap(ret);
    }
    /**
    * @returns {Registers}
    */
    registers_ws() {
        const ret = wasm.chip8neo_registers_ws(this.ptr);
        return Registers.__wrap(ret);
    }
    /**
    * @param {Uint8Array} rom
    */
    load_rom_ws(rom) {
        const ptr0 = passArray8ToWasm0(rom, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.chip8neo_load_rom_ws(this.ptr, ptr0, len0);
    }
    /**
    */
    reset_ws() {
        wasm.chip8neo_reset_ws(this.ptr);
    }
    /**
    */
    reset_hard_ws() {
        wasm.chip8neo_reset_hard_ws(this.ptr);
    }
    /**
    */
    pause_ws() {
        wasm.chip8neo_pause_ws(this.ptr);
    }
    /**
    * @returns {boolean}
    */
    paused_ws() {
        const ret = wasm.chip8neo_paused_ws(this.ptr);
        return ret !== 0;
    }
    /**
    * @returns {boolean}
    */
    beep_ws() {
        const ret = wasm.chip8neo_beep_ws(this.ptr);
        return ret !== 0;
    }
    /**
    * @returns {Uint8Array}
    */
    vram_ws() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.chip8neo_vram_ws(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v0 = getArrayU8FromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 1);
            return v0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    */
    clock_ws() {
        wasm.chip8neo_clock_ws(this.ptr);
    }
    /**
    */
    clock_dt_ws() {
        wasm.chip8neo_clock_dt_ws(this.ptr);
    }
    /**
    */
    clock_st_ws() {
        wasm.chip8neo_clock_st_ws(this.ptr);
    }
    /**
    * @param {number} key
    */
    key_press_ws(key) {
        wasm.chip8neo_key_press_ws(this.ptr, key);
    }
    /**
    * @param {number} key
    */
    key_lift_ws(key) {
        wasm.chip8neo_key_lift_ws(this.ptr, key);
    }
    /**
    */
    vblank_ws() {
        wasm.chip8neo_vblank_ws(this.ptr);
    }
}
/**
*/
export class Registers {

    static __wrap(ptr) {
        const obj = Object.create(Registers.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_registers_free(ptr);
    }
    /**
    * @returns {number}
    */
    get pc() {
        const ret = wasm.__wbg_get_registers_pc(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set pc(arg0) {
        wasm.__wbg_set_registers_pc(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get i() {
        const ret = wasm.__wbg_get_registers_i(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set i(arg0) {
        wasm.__wbg_set_registers_i(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get sp() {
        const ret = wasm.__wbg_get_registers_sp(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set sp(arg0) {
        wasm.__wbg_set_registers_sp(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get dt() {
        const ret = wasm.__wbg_get_registers_dt(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set dt(arg0) {
        wasm.__wbg_set_registers_dt(this.ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get st() {
        const ret = wasm.__wbg_get_registers_st(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set st(arg0) {
        wasm.__wbg_set_registers_st(this.ptr, arg0);
    }
}

async function load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

function getImports() {
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbg_randomFillSync_6894564c2c334c42 = function() { return handleError(function (arg0, arg1, arg2) {
        getObject(arg0).randomFillSync(getArrayU8FromWasm0(arg1, arg2));
    }, arguments) };
    imports.wbg.__wbindgen_object_drop_ref = function(arg0) {
        takeObject(arg0);
    };
    imports.wbg.__wbg_getRandomValues_805f1c3d65988a5a = function() { return handleError(function (arg0, arg1) {
        getObject(arg0).getRandomValues(getObject(arg1));
    }, arguments) };
    imports.wbg.__wbg_crypto_e1d53a1d73fb10b8 = function(arg0) {
        const ret = getObject(arg0).crypto;
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_is_object = function(arg0) {
        const val = getObject(arg0);
        const ret = typeof(val) === 'object' && val !== null;
        return ret;
    };
    imports.wbg.__wbg_process_038c26bf42b093f8 = function(arg0) {
        const ret = getObject(arg0).process;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_versions_ab37218d2f0b24a8 = function(arg0) {
        const ret = getObject(arg0).versions;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_node_080f4b19d15bc1fe = function(arg0) {
        const ret = getObject(arg0).node;
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_is_string = function(arg0) {
        const ret = typeof(getObject(arg0)) === 'string';
        return ret;
    };
    imports.wbg.__wbg_msCrypto_6e7d3e1f92610cbb = function(arg0) {
        const ret = getObject(arg0).msCrypto;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_require_78a3dcfbdba9cbce = function() { return handleError(function () {
        const ret = module.require;
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbindgen_is_function = function(arg0) {
        const ret = typeof(getObject(arg0)) === 'function';
        return ret;
    };
    imports.wbg.__wbindgen_string_new = function(arg0, arg1) {
        const ret = getStringFromWasm0(arg0, arg1);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_newnoargs_2b8b6bd7753c76ba = function(arg0, arg1) {
        const ret = new Function(getStringFromWasm0(arg0, arg1));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_call_95d1ea488d03e4e8 = function() { return handleError(function (arg0, arg1) {
        const ret = getObject(arg0).call(getObject(arg1));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbindgen_object_clone_ref = function(arg0) {
        const ret = getObject(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_self_e7c1f827057f6584 = function() { return handleError(function () {
        const ret = self.self;
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_window_a09ec664e14b1b81 = function() { return handleError(function () {
        const ret = window.window;
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_globalThis_87cbb8506fecf3a9 = function() { return handleError(function () {
        const ret = globalThis.globalThis;
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_global_c85a9259e621f3db = function() { return handleError(function () {
        const ret = global.global;
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbindgen_is_undefined = function(arg0) {
        const ret = getObject(arg0) === undefined;
        return ret;
    };
    imports.wbg.__wbg_call_9495de66fdbe016b = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = getObject(arg0).call(getObject(arg1), getObject(arg2));
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_buffer_cf65c07de34b9a08 = function(arg0) {
        const ret = getObject(arg0).buffer;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_new_537b7341ce90bb31 = function(arg0) {
        const ret = new Uint8Array(getObject(arg0));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_set_17499e8aa4003ebd = function(arg0, arg1, arg2) {
        getObject(arg0).set(getObject(arg1), arg2 >>> 0);
    };
    imports.wbg.__wbg_length_27a2afe8ab42b09f = function(arg0) {
        const ret = getObject(arg0).length;
        return ret;
    };
    imports.wbg.__wbg_newwithlength_b56c882b57805732 = function(arg0) {
        const ret = new Uint8Array(arg0 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_subarray_7526649b91a252a6 = function(arg0, arg1, arg2) {
        const ret = getObject(arg0).subarray(arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_throw = function(arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };
    imports.wbg.__wbindgen_memory = function() {
        const ret = wasm.memory;
        return addHeapObject(ret);
    };

    return imports;
}

function initMemory(imports, maybe_memory) {

}

function finalizeInit(instance, module) {
    wasm = instance.exports;
    init.__wbindgen_wasm_module = module;
    cachedInt32Memory0 = null;
    cachedUint8Memory0 = null;


    return wasm;
}

function initSync(module) {
    const imports = getImports();

    initMemory(imports);

    if (!(module instanceof WebAssembly.Module)) {
        module = new WebAssembly.Module(module);
    }

    const instance = new WebAssembly.Instance(module, imports);

    return finalizeInit(instance, module);
}

async function init(input) {
    if (typeof input === 'undefined') {
        input = new URL('chip_ahoyto_bg.wasm', import.meta.url);
    }
    const imports = getImports();

    if (typeof input === 'string' || (typeof Request === 'function' && input instanceof Request) || (typeof URL === 'function' && input instanceof URL)) {
        input = fetch(input);
    }

    initMemory(imports);

    const { instance, module } = await load(await input, imports);

    return finalizeInit(instance, module);
}

export { initSync }
export default init;
