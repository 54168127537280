{
    "name": "chip-ahoyto-web",
    "version": "0.4.2",
    "description": "The web version of CHIP-Ahoyto",
    "repository": {
        "type": "git",
        "url": "git+https://gitlab.stage.hive.pt/joamag/chip-ahoyto.git"
    },
    "license": "Apache-2.0",
    "scripts": {
        "build": "parcel build index.html",
        "dev": "parcel index.html",
        "lint": "eslint .",
        "nodemon": "nodemon --exec \"parcel --no-cache index.html\"",
        "pretty": "prettier --config .prettierrc \"./**/*.{js,ts,tsx,json}\" --write",
        "start": "npm run build",
        "watch": "parcel watch index.html"
    },
    "source": "index.ts",
    "devDependencies": {
        "@parcel/transformer-typescript-tsc": "^2.8.3",
        "@types/react": "^18.0.28",
        "@types/react-dom": "^18.0.10",
        "@typescript-eslint/eslint-plugin": "^5.51.0",
        "@typescript-eslint/parser": "^5.51.0",
        "emukit": "^0.6.3",
        "eslint": "^8.34.0",
        "nodemon": "^2.0.20",
        "parcel": "^2.8.3",
        "prettier": "^2.8.4",
        "process": "^0.11.10",
        "react": "^18.2.0",
        "react-dom": "^18.2.0",
        "typescript": "^4.9.5"
    }
}
