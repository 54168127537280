{
    "name": "emukit",
    "version": "0.6.3",
    "description": "Emulation Web UI toolkit",
    "repository": {
        "type": "git",
        "url": "git+https://github.com/joamag/emukit.git"
    },
    "license": "Apache-2.0",
    "scripts": {
        "lint": "eslint .",
        "pretty": "prettier --config .prettierrc \"./**/*.{js,ts,tsx,json}\" --write",
        "test": "npm run test:mocha",
        "test:mocha": "mocha --recursive test"
    },
    "source": "index.ts",
    "peerDependencies": {
        "@types/react": "^18.0.26",
        "@types/react-dom": "^18.0.9",
        "react": "^18.2.0",
        "react-dom": "^18.2.0"
    },
    "devDependencies": {
        "@types/react": "^18.0.26",
        "@types/react-dom": "^18.0.9",
        "@typescript-eslint/eslint-plugin": "^5.46.0",
        "@typescript-eslint/parser": "^5.46.0",
        "eslint": "^8.29.0",
        "mocha": "^10.1.0",
        "prettier": "^2.8.1",
        "react": "^18.2.0",
        "react-dom": "^18.2.0",
        "typescript": "^4.9.4"
    }
}
